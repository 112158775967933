import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { actionTypes } from '../ActionTypes';
import { StatusType, NotificationAction } from '../Common/NotificationStore';
import { IRecipientSignatureDetails, RecipientType, initialClientSignatureStatus } from '../../Core/Domain/ViewModels/ClientSignatureStatus';
import { FileSignatureDataViewModel } from '../../Core/Domain/ViewModels/DocumentViewModel';
import { ISignerControlDataModal } from '../../Core/Domain/ViewModels/SignerControlDataModal';
import { IDocument } from '../../Core/Domain/ViewModels/Document';
import { DocumentAdapter, IDocumentAdapter } from '../../Core/Services/Adapters/ControlDataAdapter/ControlDataAdapter';
import { axiosFetch, storeTokenInMemory } from '../../Core/Services/DataAccess/DataService.Axios';
import { AxiosResponse } from 'axios';
import { HttpAction } from '../Common/LoaderStore';
import { container } from '../../Startup/inversify.config';
import { TYPES } from '../../Startup/types';
import { ILocalStore } from '../../Core/Utilities/LocalStore';
import { EMPTY_STRING, getUserTimeZone, isStringNullOrEmpty } from '../../components/Helper/HelperFunctions';
import { LoggerFactory } from '../../Logger/LoggerFactory';
import { validateError } from '../../components/Helper/Validations';
import { ISignFinishedResponse } from '../../Core/Domain/ViewModels/ISignFinishedResponse';
const logger = new LoggerFactory().getTelemetryLogger();

const localStore = container.get<ILocalStore>(TYPES.ILocalStore);
export interface ISignerData {
    SignatureStatus: IRecipientSignatureDetails;
    DocumentDetails: IDocument[];
    AssignToDelegateeSigner: boolean
}

export interface ReceiveAssigntoDelegateeAction {
    type: actionTypes.RECEIVE_ASSIGN_TO_DELEGATEE_STATUS;
    AssignToDelegateeSigner: boolean;
}

export interface RequestClientSignatureStatusAction {
    type: actionTypes.REQUEST_CLIENT_SIGNATURE_STATUS;
    clientGuid: string;
}

export interface ReceiveClientSignatureStatusAction {
    type: actionTypes.RECEIVE_CLIENT_SIGNATURE_STATUS;
    SignatureStatus: IRecipientSignatureDetails;
}

export interface RequestDocumentControlsAction {
    type: actionTypes.REQUEST_DOCUMENT_CONTROLS;
    clientGuid: string;
}

export interface ReceiveDocumentControlsAction {
    type: actionTypes.RECEIVE_DOCUMENT_CONTROLS;
    DocumentDetails: IDocument[];
}

export interface DeclineSigningAction {
    type: actionTypes.DECLINE_SIGNING;
    clientGuid: string;
    remarks: string;
}

export interface DeclineDelegationAction {
    type: actionTypes.DECLINE_DELEGATION;
    assignToDelegateeSigner: boolean;
}

type KnownAction =
    DispatchAction |
    HttpAction |
    NotificationAction;

type DispatchAction = RequestClientSignatureStatusAction | ReceiveClientSignatureStatusAction |
    RequestDocumentControlsAction | ReceiveDocumentControlsAction | ReceiveAssigntoDelegateeAction | DeclineDelegationAction


export const actionCreators = {
    requestSignatureInfo: (clientGuid: string, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        axiosFetch(clientGuid).get<IRecipientSignatureDetails>('api/Signing/GetRecipientSignatureStatus')
            .then(function (response: AxiosResponse<IRecipientSignatureDetails>) {
                const { data } = response;
                dispatch({
                    type: actionTypes.RECEIVE_CLIENT_SIGNATURE_STATUS, SignatureStatus: data
                });
                dispatch({ type: actionTypes.COMPLETE_RESPONSE, loading: false });
                if (callback) {
                    callback();
                }
            })
            .catch(function (error: any) {
                const message = validateError(error);
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: message,
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.COMPLETE_RESPONSE, loading: false });

                logger.trackError(`requestSignatureInfo failed for the request having clientGuid ${clientGuid} with error ${message}`)
            });
        dispatch({ type: actionTypes.REQUEST_CLIENT_SIGNATURE_STATUS, clientGuid: clientGuid });
        dispatch({ type: actionTypes.INITIATE_REQUEST, loading: true });
    },
    requestDocumentControls: (clientGuid: string, isControlsDisabled: boolean, skipTracking?: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        axiosFetch(clientGuid).get<FileSignatureDataViewModel[]>('api/Signing/GetDocumentSignatureDataAsync?skipTracking=' + skipTracking + '&userTimeZone=' + getUserTimeZone())
            .then(function (response: AxiosResponse<FileSignatureDataViewModel[]>) {
                const { data } = response;
                const documentAdapter: IDocumentAdapter = DocumentAdapter.create();
                dispatch({
                    type: actionTypes.RECEIVE_DOCUMENT_CONTROLS, DocumentDetails: isControlsDisabled ?
                        documentAdapter.convertToClientModelWithDisable(data) :
                        documentAdapter.convertToClientModel(data)
                });

            })
            .catch(function (error: any) {
                const message = validateError(error);
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: message,
                    statusType: StatusType.Error
                });
                logger.trackError(`requestDocumentControls failed for the request having clientGuid ${clientGuid} with error ${message}`)
            });
        dispatch({ type: actionTypes.REQUEST_DOCUMENT_CONTROLS, clientGuid: clientGuid });
    },
    declineSigning: (clientGuid: string, remarks: string, callback: (status: boolean) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const options = {
            headers: { 'Accept': 'application/json' }
        };

        const formData = new FormData();
        formData.append('remarks', remarks);
        axiosFetch(clientGuid).get<boolean>('api/Signing/checkIfValidDocument')
            .then(function (response: AxiosResponse<boolean>) {
                const { data } = response;
                if (data == true) {
                    axiosFetch(clientGuid).post<boolean>('api/Signing/DeclineSigning', formData, options)
                        .then(function (response: AxiosResponse<boolean>) {
                            const successMessage = 'Declined successfully!';
                            dispatch({
                                type: actionTypes.NOTIFICATION, statusMessage: successMessage,
                                statusType: StatusType.Success
                            });
                            callback(true);
                        })
                        .catch(function (error: any) {
                            callback(false);
                            logger.trackError(`declineSigning failed for the request having clientGuid ${clientGuid} with error ${error.message}`)
                        });
                }
                else {
                    localStore.remove('loggedIn');
                    window.location.href = './invalid';
                }
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error,
                    statusType: StatusType.Error
                });


            });
    },

    delegationCancelled: (clientGuid: string, callback: (status: boolean) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const options = {
            headers: { 'Accept': 'application/json' }
        };
        axiosFetch(clientGuid).get<boolean>('api/Signing/checkIfValidDocument')
            .then(function (response: AxiosResponse<boolean>) {
                const { data } = response;
                if (data == true) {
                    axiosFetch(clientGuid).post<boolean>('api/Signing/DelegationCancel', options)
                        .then(function (response: AxiosResponse<boolean>) {
                            const { data } = response;
                            if (data) {
                                dispatch({ type: actionTypes.DECLINE_DELEGATION, assignToDelegateeSigner: false })
                                dispatch(actionCreators.requestDocumentControls(clientGuid, false, true));
                            }
                            dispatch({ type: actionTypes.COMPLETE_RESPONSE, loading: false });
                            callback(data);
                        })
                        .catch(function (error: any) {
                            dispatch({
                                type: actionTypes.NOTIFICATION, statusMessage: error,
                                statusType: StatusType.Error
                            });

                            logger.trackError(`delegationCancelled failed for the request having parameters ${JSON.stringify(clientGuid)} with error ${error.message}`)
                        });
                    dispatch({ type: actionTypes.INITIATE_REQUEST, loading: true });
                }
                else {
                    localStore.remove('loggedIn');
                    window.location.href = './invalid';
                }
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error,
                    statusType: StatusType.Error
                });
            });
    },

    assignToDelegatee: (clientGuid: string, email: string, name: string, reason: string, callback: (status: boolean) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const options = {
            headers: { 'Accept': 'application/json' }
        };
        const formData = new FormData();
        formData.append('email', email);
        formData.append('name', name);
        formData.append('reason', reason);
        axiosFetch(clientGuid).get<boolean>('api/Signing/checkIfValidDocument')
            .then(function (response: AxiosResponse<boolean>) {
                const { data } = response;
                if (data == true) {
                    axiosFetch(clientGuid).post<boolean>('api/Signing/AssignToDelegatee', formData, options)
                        .then(function (response: AxiosResponse<boolean>) {
                            const { data } = response;
                            dispatch({
                                type: actionTypes.RECEIVE_ASSIGN_TO_DELEGATEE_STATUS, AssignToDelegateeSigner: data
                            });
                            callback(data);

                            dispatch(actionCreators.requestDocumentControls(clientGuid, data, true));

                            dispatch({ type: actionTypes.COMPLETE_RESPONSE, loading: false });
                        })
                        .catch(function (error: any) {
                            callback(false);
                            logger.trackError(`assignToDelegatee failed for the request having parameters ${JSON.stringify({
                                clientGuid: clientGuid, email: email,
                                name: name, reason: reason
                            })} with error ${error.message}`)
                        });
                    dispatch({ type: actionTypes.INITIATE_REQUEST, loading: true });
                }
                else {
                    localStore.remove('loggedIn');
                    window.location.href = './invalid';
                }
            })
            .catch(function (error: any) {
                callback(false);
            });
    },

    updateDelegatedSigner: (clientGuid: string, email: string, name: string, reason: string, callback: (status: boolean) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const options = {
            headers: { 'Accept': 'application/json' }
        };

        const formData = new FormData();
        formData.append('email', email);
        formData.append('name', name);
        formData.append('reason', reason);
        axiosFetch(clientGuid).get<boolean>('api/Signing/checkIfValidDocument')
            .then(function (response: AxiosResponse<boolean>) {
                const { data } = response;
                if (data == true) {
                    axiosFetch(clientGuid).post<boolean>('api/Signing/UpdateDelegation', formData, options)
                        .then(function (response: AxiosResponse<boolean>) {
                            const { data } = response;
                            dispatch({ type: actionTypes.COMPLETE_RESPONSE, loading: false });
                            callback(data);
                        })
                        .catch(function (error: any) {
                            logger.trackError(`updateDelegatedSigner failed for the request having parameters ${JSON.stringify({
                                clientGuid: clientGuid, email: email,
                                name: name, reason: reason
                            })} with error ${error.message}`)
                            callback(false);
                        });
                    dispatch({ type: actionTypes.INITIATE_REQUEST, loading: true });
                }
                else {
                    localStore.remove('loggedIn');
                    window.location.href = './invalid';
                }
                dispatch({ type: actionTypes.COMPLETE_RESPONSE, loading: false });
            })
            .catch(function (error: any) {
                logger.trackError(`updateDelegatedSigner failed for the request having parameters ${JSON.stringify({
                    clientGuid: clientGuid, email: email,
                    name: name, reason: reason
                })} with error ${error.message}`);
                callback(false);
            });

    },

    sign: (signatureData: ISignerControlDataModal, callback: (status: ISignFinishedResponse) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const options = {
            headers: {
                'Accept': 'application/json, text/plain, *',
                'Content-Type': 'application/json; charset=utf-8'
            }
        };
        dispatch({ type: actionTypes.INITIATE_REQUEST, loading: true });
        return axiosFetch(signatureData.recipientId).get<boolean>('api/Signing/checkIfValidDocument')
            .then(function (response: AxiosResponse<boolean>) {
                const { data } = response;
                if (data == true) {
                    axiosFetch(signatureData.recipientId).post<boolean>('api/Signing/Sign?userTimeZone=' + getUserTimeZone(), signatureData, options)
                        .then(function (response: AxiosResponse<ISignFinishedResponse>) {
                            const { data } = response;
                            localStore.set(signatureData.recipientId, 'esigned');
                            let signResponse :ISignFinishedResponse = { isSignFinish: true, signFinishMessage: ""};
                            callback(signResponse);
                        })
                        .catch(function (error: any) {
                            let signResponse :ISignFinishedResponse = { isSignFinish: false, signFinishMessage: error.response.data};
                            callback(signResponse);
                            logger.trackError(`sign failed for the request having parameters ${JSON.stringify(signatureData)} with error ${error.message}`)
                        });
                }
                else {
                    localStore.remove('loggedIn');
                    window.location.href = './invalid';
                }

            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error,
                    statusType: StatusType.Error
                });
            });



    }
}

const unloadedState: ISignerData = {
    SignatureStatus: initialClientSignatureStatus,
    DocumentDetails: [],
    AssignToDelegateeSigner: false
} as ISignerData;

export const signerReducer: Reducer<ISignerData> = (state: ISignerData = unloadedState, incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    switch (action.type) {
        case actionTypes.REQUEST_CLIENT_SIGNATURE_STATUS:
            return {
                SignatureStatus: initialClientSignatureStatus,
                DocumentDetails: state.DocumentDetails,
                AssignToDelegateeSigner: state.AssignToDelegateeSigner
            } as ISignerData;
        case actionTypes.RECEIVE_CLIENT_SIGNATURE_STATUS:
            return {
                SignatureStatus: action.SignatureStatus,
                DocumentDetails: state.DocumentDetails,
                AssignToDelegateeSigner: !isStringNullOrEmpty(action.SignatureStatus.delegateeInfo?.EmailId) &&
                                            action.SignatureStatus.recipientType != RecipientType.Delegatte
            } as ISignerData;
        case actionTypes.REQUEST_DOCUMENT_CONTROLS:
            return {
                SignatureStatus: state.SignatureStatus,
                DocumentDetails: [],
                AssignToDelegateeSigner: state.AssignToDelegateeSigner
            } as ISignerData;
        case actionTypes.RECEIVE_DOCUMENT_CONTROLS:
            return {
                SignatureStatus: state.SignatureStatus,
                DocumentDetails: action.DocumentDetails,
                AssignToDelegateeSigner: state.AssignToDelegateeSigner
            } as ISignerData;
        case actionTypes.RECEIVE_ASSIGN_TO_DELEGATEE_STATUS:
            return {
                SignatureStatus: state.SignatureStatus,
                DocumentDetails: state.DocumentDetails,
                AssignToDelegateeSigner: action.AssignToDelegateeSigner,
            } as ISignerData;
        case actionTypes.DECLINE_DELEGATION:
            var signatureData: ISignerData = state;
            signatureData.SignatureStatus.delegateeInfo.EmailId = EMPTY_STRING;
            signatureData.SignatureStatus.delegateeInfo.Name = EMPTY_STRING;
            return {
                SignatureStatus: signatureData.SignatureStatus,
                DocumentDetails: state.DocumentDetails,
                AssignToDelegateeSigner: action.assignToDelegateeSigner,
            } as ISignerData;
        case actionTypes.RECEIVE_ASSIGN_TO_DELEGATEE_STATUS:
            return {
                SignatureStatus: state.SignatureStatus,
                DocumentDetails: state.DocumentDetails,
                AssignToDelegateeSigner: action.AssignToDelegateeSigner,
            } as ISignerData;
        default:
            return state || unloadedState;
    }
};